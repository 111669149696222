body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
    /* IOS 아이폰 Input 태그에 기본 둥근 테두리 및 그림자 제거 */
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}

* {
    outline-color: transparent;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}
